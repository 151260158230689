import React from 'react';
import { Box } from '@mui/material';
import Typography from '../../components/typography';
import theme from '../../assets/theme';

export const Employees = () => {
  return (
    <Box sx={{ backgroundColor: theme.palette.primary.main }}>
      <Box paddingLeft={10} paddingTop={16}>
        <Typography variant="h2" color="primary.dark" fontWeight={700}>
          + 800
        </Typography>

        <Typography color="primary.dark" variant="h3">
          JobRad-Mitarbeitende sorgen
        </Typography>

        <Typography color="primary.dark" variant="h3">
          für eine reibungslose
        </Typography>

        <Typography color="primary.dark" variant="h3">
          Abwicklung aller Prozesse.
        </Typography>
      </Box>
    </Box>
  );
};
