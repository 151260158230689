import React from 'react';
import { Box } from '@mui/material';
import Typography from '../../components/typography';
import theme from '../../assets/theme';
import Img from '../../assets/images/heart.svg';

export const ReducesRiskHeartAttack = () => {
  return (
    <Box sx={{ backgroundColor: theme.palette.secondary.dark }}>
      <Box
        marginLeft={20}
        height="100vh"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box minWidth="fit-content">
          <Typography color="secondary.light" variant="h6">
            Fahrradfahren senkt das
          </Typography>
          <Typography color="secondary.light" fontWeight={700} variant="h6">
            Herzinfarktrisiko um 50 %.
          </Typography>
          <Typography color="secondary.light" variant="h6">
            Schon bei täglichen Strecken ab 4,5 km.
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column" alignItems="center">
          <img src={Img} alt="Heart" className="imgSmaller" />
        </Box>

        <Box></Box>
      </Box>
    </Box>
  );
};
