import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import theme from '../../assets/theme';
import FillingText from '../../components/fillingText';
import useActiveSession from '../../hooks/useActiveSection';
import Typography from '../../components/typography';
import { devices, sizes } from '../../assets/devices';

export const VisionToMission = () => {
  const [isActiveSession] = useActiveSession('#VisionToMission');
  const [showText, setShowText] = useState(false);

  useEffect(() => {
    if (isActiveSession && !showText) {
      setShowText(true);
    }
  }, [isActiveSession]);

  return (
    <Box sx={{ backgroundColor: theme.palette.secondary.main }}>
      <Box paddingLeft={10} paddingTop={10}>
        <Box maxWidth="1410px">
          {showText && (
            <Typography
              color="primary.dark"
              variant="h3"
              sx={{ lineHeight: '115%', [devices.iPad]: { fontSize: '85px' } }}
            >
              {window.innerWidth > sizes.iPad ? (
                <FillingText>
                  Was wir dem Fachhandel
                  bringen?&amp;&amp;&amp;&amp;&amp;&amp;&amp;&amp;&amp;&amp;&amp;&amp;&amp;&amp;&amp;&amp;&amp;&amp;
                  20&thinsp;% vom Radumsatz!
                </FillingText>
              ) : (
                <FillingText>
                  Was wir dem Fachhandel bringen? 20&thinsp;% vom Radumsatz!
                </FillingText>
              )}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};
