import React from 'react';
import { Box } from '@mui/material';
import theme from '../../../assets/theme';
import ReactFullpage from '@fullpage/react-fullpage';
import Footer from '../../../components/footer';
import {
  EmployeesLeasing,
  EmployersIncrease,
  LogoViews,
  ReturningCustomers,
  ServiceBikeLeasing,
} from '../../../sections';
import { StrongBrand } from '../../../components/strongBrand';

const B2BTopic4 = () => (
  <ReactFullpage
    licenseKey={'YOUR_KEY_HERE'}
    credits={{ enabled: false }}
    scrollingSpeed={1000}
    anchors={[
      'LogoViews',
      'ServiceBikeLeasing',
      'StrongBrand',
      'ReturningCustomers',
      'EmployeesLeasing',
      'EmployersIncrease',
      'footer',
    ]}
    render={({ state, fullpageApi }) => {
      return (
        <ReactFullpage.Wrapper>
          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.primary.main,
            }}
          >
            <LogoViews />
          </Box>

          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.secondary.main,
            }}
          >
            <ServiceBikeLeasing />
          </Box>

          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.primary.dark,
            }}
          >
            <StrongBrand />
          </Box>

          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.primary.dark,
            }}
          >
            <ReturningCustomers />
          </Box>

          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.primary.main,
            }}
          >
            <EmployeesLeasing />
          </Box>

          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.secondary.dark,
            }}
          >
            <EmployersIncrease />
          </Box>

          <Box className="section">
            <Footer
              title="Maximal umsatzstark."
              url="/B2B/1#JobBikesSince2012"
            />
          </Box>
        </ReactFullpage.Wrapper>
      );
    }}
  />
);

export default B2BTopic4;
