import { Button, keyframes, styled } from '@mui/material';
import { devices } from '../../assets/devices';
import theme from '../../assets/theme';

const pulse = keyframes`
0% {
  transform: scale(.9);
}
80% {
  transform: scale(1);
}
100% {
  transform: scale(.9);
}
`;

const stroke = keyframes`
0% {
  opacity: 0;
}
50% {
  opacity: 0.5;
}
100% {
  width: 150%;
  height: 150%;
  opacity: 0
}
`;

const PulsingButtonStyled = styled(Button)({
  width: 261,
  height: 261,
  zIndex: 99,
  filter: 'drop-shadow(0px 16px 16px rgba(0, 0, 0, 0.12))',
  textTransform: 'none',
  fontSize: 20,
  borderRadius: '100%',
  fontWeight: 300,
  border: '8px solid #FFFFFF',
  animation: `${pulse} 1.5s infinite`,
  borderColor: theme.palette.secondary.light,
  backgroundColor: '#FFFA65',
  color: theme.palette.secondary.dark,
  textAlign: 'center',
  '&:hover': {
    backgroundColor: '#FFFA65',
    color: theme.palette.secondary.dark,
    boxShadow: '0 0 0 0 #FFFA65',
  },
  '&:before, &:after': {
    content: '""',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '50%',
    border: '2px solid #FFFA65',
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    opacity: 0.9,
    animation: `${stroke} 1.5s infinite`,
  },
  '&:after': {
    animationDelay: '0.5s',
  },
  [devices.iPad]: {
    width: '180px',
    height: '180px',
  },
});

export default PulsingButtonStyled;
