import React from 'react';
import { Box } from '@mui/material';
import Logo from '../../assets/images/darkLogo.svg';
import Typography from '../../components/typography';
import theme from '../../assets/theme';
import Lottie from 'react-lottie-player';
import DragIndicator from '../../assets/lottie/darkDragIndicator.json';
import useActiveSession from '../../hooks/useActiveSection';
import CountUp from 'react-countup';

export const CompanyBikeLeasing = () => {
  const [isActiveSession] = useActiveSession('#CompanyBikeLeasing');

  return (
    <Box sx={{ backgroundColor: theme.palette.secondary.main }}>
      <Box paddingLeft={6}>
        <Box marginTop={6} marginBottom={9}>
          <img src={Logo} alt="Jobrad Logo" />
        </Box>

        <Box position="absolute" height="100%" top="0" right="0">
          <Box display="flex" alignItems="center" height="100%">
            <Lottie loop play animationData={DragIndicator} />
          </Box>
        </Box>

        <Typography
          color="primary.dark"
          variant="h1"
          sx={{ marginBottom: '48px' }}
        >
          {isActiveSession ? (
            <CountUp start={0} end={56} duration={2} separator="." />
          ) : (
            '56'
          )}
          &#8201;%
        </Typography>

        <Typography color="primary.dark" variant="h5">
          der JobRadler:innen haben sich dank
        </Typography>

        <Typography color="primary.dark" variant="h5">
          Dienstradleasing ein Fahrrad zugelegt, das
        </Typography>

        <Typography color="primary.dark" variant="h5">
          sie sich sonst nicht leisten würden.
        </Typography>
      </Box>
    </Box>
  );
};
