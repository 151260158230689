import React from 'react';
import { Box } from '@mui/material';
import Typography from '../../components/typography';
import theme from '../../assets/theme';
import useActiveSession from '../../hooks/useActiveSection';
import Lottie from 'react-lottie-player';
import Route from '../../assets/lottie/Map_Frankfurt_to_Freiburg.json';

export const ResultsRoute = () => {
  const [isActiveSession] = useActiveSession('#ResultsRoute');

  return (
    <Box sx={{ backgroundColor: theme.palette.primary.main }}>
      <Box display="flex" alignItems="center" height="100vh" paddingRight={4}>
        <Box paddingLeft={10} minWidth="fit-content">
          <Typography color="primary.dark" variant="h5">
            Reiht man die Räder
          </Typography>

          <Typography color="primary.dark" variant="h5">
            aneinander, ergibt das zweimal
          </Typography>

          <Typography color="primary.dark" variant="h5">
            die Strecke von <b>Freiburg nach</b>
          </Typography>

          <Typography color="primary.dark" variant="h5">
            <b>Frankfurt</b> und zurück.
          </Typography>
        </Box>

        <Box className="lottie-wrapper">
          <Lottie
            loop
            play={isActiveSession}
            animationData={Route}
            className="lottie-default"
          />
        </Box>
      </Box>
    </Box>
  );
};
