import React from 'react';
import { Box } from '@mui/material';
import theme from '../../../assets/theme';
import Footer from '../../../components/footer';
import ReactFullpage from '@fullpage/react-fullpage';
import {
  Diversity,
  Employees,
  OrdersIn2022,
  Stationary,
  ThisIsHow,
} from '../../../sections';

const B2CTopic2 = () => (
  <ReactFullpage
    licenseKey={'YOUR_KEY_HERE'}
    credits={{ enabled: false }}
    scrollingSpeed={1000}
    anchors={[
      'OrdersIn2022',
      'Diversity',
      'Stationary',
      'Employees',
      'ThisIsHow',
      'footer',
    ]}
    render={({ state, fullpageApi }) => {
      return (
        <ReactFullpage.Wrapper>
          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.primary.main,
            }}
          >
            <OrdersIn2022 />
          </Box>

          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.secondary.main,
            }}
          >
            <Diversity />
          </Box>

          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.secondary.dark,
            }}
          >
            <Stationary />
          </Box>

          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.primary.main,
            }}
          >
            <Employees />
          </Box>

          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.secondary.main,
            }}
          >
            <ThisIsHow />
          </Box>

          <Box className="section">
            <Footer title="Mehr Geld sparen." url="/B2C/3#CompanyBikeLeasing" />
          </Box>
        </ReactFullpage.Wrapper>
      );
    }}
  />
);

export default B2CTopic2;
