import React from 'react';
import { Box } from '@mui/material';
import Logo from '../../assets/images/logo.svg';
import Typography from '../../components/typography';
import theme from '../../assets/theme';
import Lottie from 'react-lottie-player';
import DragIndicator from '../../assets/lottie/dragIndicator.json';
import useActiveSession from '../../hooks/useActiveSection';
import CountUp from 'react-countup';

export const SickDays = () => {
  const [isActiveSession] = useActiveSession('#SickDays');

  return (
    <Box sx={{ backgroundColor: theme.palette.primary.dark }}>
      <Box paddingLeft={6}>
        <Box marginTop={6} marginBottom={9}>
          <img src={Logo} alt="Jobrad Logo" />
        </Box>

        <Box position="absolute" height="100%" top="0" right="0">
          <Box display="flex" alignItems="center" height="100%">
            <Lottie loop play animationData={DragIndicator} />
          </Box>
        </Box>

        <Typography variant="h1" sx={{ marginBottom: '48px' }}>
          -
          {isActiveSession ? (
            <CountUp start={0} end={2} duration={2} separator="." />
          ) : (
            '2'
          )}
        </Typography>

        <Typography variant="h5">
          Regelmäßige Radpendler:innen sind im Schnitt zwei
        </Typography>

        <Typography variant="h5">Tage weniger krank pro Jahr.</Typography>
      </Box>
    </Box>
  );
};
