import { Box } from '@mui/material';
import React from 'react';
import Lottie from 'react-lottie-player';
import { devices } from '../../assets/devices';
import EuroMorph from '../../assets/lottie/EuroMorph.json';
import theme from '../../assets/theme';
import Typography from '../../components/typography';
import useActiveSession from '../../hooks/useActiveSection';

export const ServiceBudget = () => {
  const [isActiveSession] = useActiveSession('#ServiceBudget');

  return (
    <Box sx={{ backgroundColor: theme.palette.secondary.dark }}>
      <Box
        marginLeft={20}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        height="100vh"
        sx={{ [devices.iPad]: { marginLeft: 12 } }}
      >
        <Box>
          <Typography
            fontWeight={700}
            color="secondary.light"
            variant="h4"
            sx={{ marginBottom: '24px' }}
          >
            140 Mio. €
          </Typography>
          <Typography color="secondary.light" variant="h6">
            allein für die ausstehenden Inspektionen in den nächsten drei
            Jahren.
          </Typography>
        </Box>

        <Box className="lottie-wrapper">
          <Lottie
            loop
            play={isActiveSession}
            animationData={EuroMorph}
            className=" lottie-default"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          />
        </Box>

        <Box></Box>
      </Box>
    </Box>
  );
};
