import { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router';

export function useModeNavs() {
  let { topic } = useParams();
  const location = useLocation();
  const pathname = location.pathname.toUpperCase();
  const isB2B = pathname.includes('B2B');

  const navsB2B = [
    {
      title: 'Maximal umsatzstark',
      active: topic === '1',
      url: '/B2B/1#JobBikesSince2012',
    },
    {
      title: 'Maximaler Service',
      active: topic === '2',
      url: '/B2B/2#ServicePackage',
    },
    {
      title: 'Maximaler Einsatz',
      active: topic === '3',
      url: '/B2B/3#Leasing',
    },
    {
      title: 'Maximal präsent',
      active: topic === '4',
      url: '/B2B/4#LogoViews',
    },
  ];

  const navsB2C = [
    {
      title: 'Mehr Dienstrad',
      active: topic === '1',
      url: '/B2C/1#JobBikesSince2012',
    },
    {
      title: 'Mehr Möglichkeiten',
      active: topic === '2',
      url: '/B2C/2#OrdersIn2022',
    },
    {
      title: 'Mehr Geld sparen',
      active: topic === '3',
      url: '/B2C/3#CompanyBikeLeasing',
    },
    { title: 'Mehr Gesundheit', active: topic === '4', url: '/B2C/4#SickDays' },
    {
      title: 'Mehr Nachhaltigkeit',
      active: topic === '5',
      url: '/B2C/5#SavesCO2',
    },
  ];

  const [modeNavs, setModeNavs] = useState<
    {
      title: string;
      active: boolean;
      url: string;
    }[]
  >([]);

  useEffect(() => {
    function updateNavs() {
      setModeNavs(isB2B ? navsB2B : navsB2C);
    }
    updateNavs();
  }, [location]);

  return { modeNavs };
}
