import React, { PropsWithChildren } from 'react';
import {
  LetterWrapperProp,
  SplitText,
  WordWrapperProp,
} from '@cyriacbr/react-split-text';
import { motion } from 'framer-motion';

const LetterWrapper: React.FC<PropsWithChildren<LetterWrapperProp>> = ({
  children,
  countIndex,
}) => {
  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <span>{children === '&' ? '  ' : children}</span>
      <span
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
      >
        <motion.span
          transition={{
            ease: 'anticipate',
            duration: 1,
            delay: 0.025 * countIndex,
          }}
          initial={{
            clipPath: `polygon(0% 0%, 0% 0%, 0% 0%, 0% 0%)`,
          }}
          animate={{ clipPath: `polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)` }}
          style={{ color: 'rgba(43, 55, 61, 1)' }}
        >
          {children === '&' ? '  ' : children}
        </motion.span>
      </span>
    </div>
  );
};

const WordWrapper: React.FC<PropsWithChildren<WordWrapperProp>> = ({
  children,
}) => {
  return (
    <span style={{ whiteSpace: 'break-spaces' }}>
      {children === '&' ? '  ' : children}
    </span>
  );
};

const MemoizedLetterWrapper = React.memo(LetterWrapper);
const MemoizedWordWrapper = React.memo(WordWrapper);

type FillingTextProps = PropsWithChildren<{
  color?: string;
}>;

const FillingText = ({ color, children }: FillingTextProps) => (
  /* @ts-ignore */
  <SplitText
    style={{ color: color || '#FF8AC5' }}
    LetterWrapper={MemoizedLetterWrapper}
    WordWrapper={MemoizedWordWrapper}
    className="fillingText"
  >
    {children}
  </SplitText>
);

export default FillingText;
