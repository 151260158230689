import React from 'react';
import { Box } from '@mui/material';
import CountUp from 'react-countup';
import Typography from '../../components/typography';
import theme from '../../assets/theme';
import useActiveSession from '../../hooks/useActiveSection';

export const SupportCalls = () => {
  const [isActiveSession] = useActiveSession('#SupportCalls');

  return (
    <Box sx={{ backgroundColor: theme.palette.primary.main }}>
      <Box paddingX={10} paddingTop={10}>
        <Typography
          variant="h2"
          color="primary.dark"
          fontWeight={700}
          sx={{ marginBottom: 1 }}
        >
          {isActiveSession ? (
            <CountUp start={0} end={35000} duration={2} separator="." />
          ) : (
            '35.000'
          )}{' '}
          Anrufe 2023
        </Typography>

        <Typography color="primary.dark" variant="h3" fontWeight={300}>
          Ausgezeichneter Support für unsere Partner:innen.
        </Typography>

        <Typography color="primary.dark" variant="h3" fontWeight={300}>
          Top erreichbar, kompetent und erfahren.
        </Typography>
        {/*
        <Typography color="primary.dark" variant="h3" fontWeight={300}>
          weiterhilft
        </Typography> */}
      </Box>
    </Box>
  );
};
