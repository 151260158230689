import React from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import theme from '../../assets/theme';
import Typography from '../typography';
import { devices } from '../../assets/devices';
import { useRevelaer } from '../../helpers/useRevealer';
import { useModeNavs } from '../../helpers/useModeNavs';

const Menu = () => {
  const navigate = useNavigate();
  const reveal = useRevelaer();
  const { modeNavs } = useModeNavs();

  let currentUrlIndex = modeNavs.findIndex((nav) => nav.active);

  return (
    <Box display="flex" alignItems="flex-end" justifyContent="center">
      <Box
        height="56px"
        borderRadius="80px"
        display="flex"
        sx={{
          backgroundColor: '#555F64',
          boxShadow: '0px 16px 16px rgba(0, 0, 0, 0.12)',
        }}
      >
        {modeNavs &&
          modeNavs.map((nav, index) => (
            <Box
              key={nav.title}
              paddingX="40px"
              height="100%"
              display="flex"
              alignItems="center"
              borderRadius="80px"
              onClick={() => {
                reveal(currentUrlIndex > index ? 'left' : 'right', () => {
                  navigate(nav.url);
                });
              }}
              sx={{
                userSelect: 'none',
                backgroundColor: nav.active
                  ? theme.palette.primary.main
                  : '#555F64',
                [devices.iPad]: {
                  paddingX: '30px',
                },
              }}
            >
              <Typography
                fontWeight={400}
                color={nav.active ? 'primary.dark' : 'primary'}
                sx={{ fontSize: '18px' }}
              >
                {nav.title}
              </Typography>
            </Box>
          ))}
      </Box>
    </Box>
  );
};

export default Menu;
