import React from 'react';
import { Box } from '@mui/material';
import theme from '../../../assets/theme';
import Footer from '../../../components/footer';
import ReactFullpage from '@fullpage/react-fullpage';
import {
  LiveHealthier,
  MiracleCure,
  ReducesRiskHeartAttack,
  SickDays,
} from '../../../sections';

const B2CTopic4 = () => (
  <ReactFullpage
    licenseKey={'YOUR_KEY_HERE'}
    credits={{ enabled: false }}
    scrollingSpeed={1000}
    anchors={[
      'SickDays',
      'LiveHealthier',
      'ReducesRiskHeartAttack',
      'MiracleCure',
      'footer',
    ]}
    render={({ state, fullpageApi }) => {
      return (
        <ReactFullpage.Wrapper>
          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.primary.dark,
            }}
          >
            <SickDays />
          </Box>

          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.secondary.main,
            }}
          >
            <LiveHealthier />
          </Box>

          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.secondary.dark,
            }}
          >
            <ReducesRiskHeartAttack />
          </Box>

          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.secondary.main,
            }}
          >
            <MiracleCure />
          </Box>

          <Box className="section">
            <Footer title="Mehr Nachhaltigkeit." url="/B2C/5#SavesCO2" />
          </Box>
        </ReactFullpage.Wrapper>
      );
    }}
  />
);

export default B2CTopic4;
