import { Box } from '@mui/material';
import React from 'react';
import Lottie from 'react-lottie-player';
import StopWatch from '../../assets/lottie/Stopwatch.json';
import theme from '../../assets/theme';
import Typography from '../../components/typography';
import useActiveSession from '../../hooks/useActiveSection';

export const OrderOnPeakDays = () => {
  const [isActiveSession] = useActiveSession('#OrderOnPeakDays');

  return (
    <Box sx={{ backgroundColor: theme.palette.primary.dark }}>
      <Box paddingLeft={10} height="100vh" display="flex" alignItems="center">
        <Box marginRight={10} width="410px">
          <Typography color="secondary.light" fontWeight={700} variant="h5">
            Über 2.500
          </Typography>
          <Typography color="secondary.light" variant="h5">
            Bestellungen an
          </Typography>
          <Typography color="secondary.light" variant="h5">
            Spitzentagen.
          </Typography>
        </Box>

        <Box className="lottie-wrapper">
          <Lottie
            loop
            animationData={StopWatch}
            play={isActiveSession}
            className="lottie-default"
          />
        </Box>
      </Box>
    </Box>
  );
};
