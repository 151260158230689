import React from 'react';
import { Box } from '@mui/material';
import Typography from '../../components/typography';
import theme from '../../assets/theme';
import Lottie from 'react-lottie-player';
import useActiveSession from '../../hooks/useActiveSection';
import Eye from '../../assets/lottie/Eye.json';
import Logo from '../../assets/images/darkLogo.svg';
import DragIndicator from '../../assets/lottie/darkDragIndicator.json';
import HauptsponsorLogo from '../../assets/images/hauptsponsorLogoBlack.svg';
import { devices } from '../../assets/devices';

export const LogoViews = () => {
  const [isActiveSession] = useActiveSession('#LogoViews');

  return (
    <Box sx={{ backgroundColor: theme.palette.primary.main }}>
      <Lottie
        loop
        play={isActiveSession}
        animationData={Eye}
        className="lottie-default"
        style={{
          position: 'absolute',
          top: '0',
          right: '0',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 0,
        }}
      />
      <Box position="absolute" height="100%" top="0" right="0">
        <Box display="flex" alignItems="center" height="100%">
          <Lottie loop play animationData={DragIndicator} />
        </Box>
      </Box>

      <Box
        paddingLeft={6}
        position="relative"
        display={'flex'}
        flexDirection={'column'}
      >
        <Box marginTop={6} marginBottom={9}>
          <img src={Logo} alt="Jobrad Logo" />
        </Box>

        <Box
          minWidth="fit-content"
          display="flex"
          flexDirection="column"
          gap={3}
          marginTop={'86px'}
          paddingLeft={'112px'}
          sx={{ [devices.iPad]: { marginTop: '16px', paddingLeft: '48px' } }}
        >
          <Box>
            <img src={HauptsponsorLogo} alt="Jobrad Hauptsponsor Logo" />
          </Box>
          <Typography color="primary.dark" variant="h4" fontWeight={700}>
            6 Mrd. Logoviews
          </Typography>
          <Typography
            color="primary.dark"
            fontWeight={300}
            variant="h6"
            sx={{ lineHeight: 1.15 }}
          >
            Einzigartige Reichweite.
            <br />
            Auch für unsere Partner:innen.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
