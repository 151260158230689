import { Box } from '@mui/material';
import React from 'react';
import CountUp from 'react-countup';
import theme from '../../assets/theme';
import Typography from '../../components/typography';
import useActiveSession from '../../hooks/useActiveSection';

export const JobBikesIn2022 = () => {
  const [isActiveSession] = useActiveSession('#JobBikesIn2022');

  return (
    <Box sx={{ backgroundColor: theme.palette.primary.main }}>
      <Box paddingLeft={10} paddingTop={10}>
        <Typography variant="h2" color="primary.dark" fontWeight={700}>
          Über{' '}
          {isActiveSession ? (
            <CountUp start={0} end={300000} duration={2} separator="." />
          ) : (
            '300.000'
          )}
        </Typography>

        <Typography color="primary.dark" variant="h3">
          JobRäder
        </Typography>

        <Typography color="primary.dark" variant="h3">
          alleine in 2023.
        </Typography>
      </Box>
    </Box>
  );
};
