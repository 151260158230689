import React from 'react';
import { Box } from '@mui/material';
import theme from '../../assets/theme';
import Typography from '../../components/typography';
import Img from '../../assets/images/parkingLot.svg';
import { devices } from '../../assets/devices';

export const ParkingLot = () => (
  <Box sx={{ backgroundColor: theme.palette.primary.dark }}>
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      marginTop="100px"
      marginLeft={10}
      sx={{
        [devices.iPad]: {
          marginTop: 0,
          height: '100vh',
        },
      }}
    >
      <Box>
        <Typography variant="h5" color="secondary.light" fontWeight={700}>
          Fahrräder brauchen
        </Typography>

        <Typography variant="h5" color="secondary.light" fontWeight={700}>
          weniger Platz.
        </Typography>

        <Typography variant="h5" color="secondary.light">
          Auf einen Autoparkplatz
        </Typography>

        <Typography variant="h5" color="secondary.light">
          passen bis zu 10 Fahrräder.
        </Typography>
      </Box>

      <Box>
        <img src={Img} alt="Parking Lot" className="imgSmaller" />
      </Box>

      <Box></Box>
    </Box>
  </Box>
);
