import React from 'react';
import { Box } from '@mui/material';
import theme from '../../../assets/theme';
import ReactFullpage from '@fullpage/react-fullpage';
import Footer from '../../../components/footer';
import {
  BikesInfo,
  JobBikesSince2012,
  OrdersVolumeIn2023,
  PriceChart,
  PriceChartComparison,
  VisionToMission,
} from '../../../sections';

const B2BTopic1 = () => (
  <ReactFullpage
    licenseKey={'YOUR_KEY_HERE'}
    credits={{ enabled: false }}
    scrollingSpeed={1000}
    anchors={[
      'JobBikesSince2012',
      'VisionToMission',
      'OrdersVolumeIn2023',
      'BikesInfo',
      'PriceChart',
      'PriceChartComparison',
      'footer',
    ]}
    render={({ state, fullpageApi }) => {
      return (
        <ReactFullpage.Wrapper>
          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.primary.dark,
            }}
          >
            <JobBikesSince2012 />
          </Box>

          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.secondary.main,
            }}
          >
            <VisionToMission />
          </Box>

          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.primary.main,
            }}
          >
            <OrdersVolumeIn2023 />
          </Box>

          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.primary.dark,
            }}
          >
            <BikesInfo />
          </Box>

          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.secondary.dark,
            }}
          >
            <PriceChart />
          </Box>

          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.secondary.dark,
            }}
          >
            <PriceChartComparison />
          </Box>

          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.primary.dark,
            }}
          >
            <Footer
              title={['Maximal Räder.', 'Plus Services.', 'Plus Zuschuss.']}
              url="/B2B/2#ServicePackage"
            />
          </Box>
        </ReactFullpage.Wrapper>
      );
    }}
  />
);

export default B2BTopic1;
