import React from 'react';
import { ButtonProps } from '../button/props';
import PulsingButtonStyled from './styles';

const PulsingButton = ({ children, onClick }: ButtonProps) => (
  <PulsingButtonStyled onClick={onClick} variant="contained">
    {children}
  </PulsingButtonStyled>
);

export default PulsingButton;
