import React from 'react';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';
import FullPage from '../pages/fullPage';

const RoutesList = () => (
  <Router>
    <Routes>
      <Route
        path=""
        element={<Navigate to="/B2B/1#JobBikesSince2012" replace />}
      />

      <Route
        path="/B2B/*"
        element={<Navigate to="/B2B/1#JobBikesSince2012" replace />}
      />
      <Route path="/B2B/:topic" element={<FullPage />} />
      <Route path="/B2C/*" element={<Navigate to="/B2C/1" replace />} />
      <Route path="/B2C/:topic" element={<FullPage />} />
    </Routes>
  </Router>
);

export default RoutesList;
