import React from 'react';
import { Box } from '@mui/material';
import theme from '../../../assets/theme';
import ReactFullpage from '@fullpage/react-fullpage';
import Footer from '../../../components/footer';
import {
  FiveMillionEmployees,
  JobBikesIn2022,
  JobBikesSince2012,
  OrderOnPeakDays,
  ResultsRoute,
  ReimaginedMobility,
  WheelsPerMinute,
} from '../../../sections';

const B2CTopic1 = () => (
  <ReactFullpage
    licenseKey={'YOUR_KEY_HERE'}
    credits={{ enabled: false }}
    scrollingSpeed={1000}
    anchors={[
      'JobBikesSince2012',
      'ReimaginedMobility',
      'OrderOnPeakDays',
      'WheelsPerMinute',
      'JobBikesIn2022',
      'ResultsRoute',
      'FiveMillionEmployees',
      'footer',
    ]}
    render={({ state, fullpageApi }) => {
      return (
        <ReactFullpage.Wrapper>
          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.primary.dark,
            }}
          >
            <JobBikesSince2012 />
          </Box>

          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.secondary.main,
            }}
          >
            <ReimaginedMobility />
          </Box>

          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.primary.dark,
            }}
          >
            <OrderOnPeakDays />
          </Box>

          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.primary.dark,
            }}
          >
            <WheelsPerMinute />
          </Box>

          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.primary.main,
            }}
          >
            <JobBikesIn2022 />
          </Box>
          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.primary.main,
            }}
          >
            <ResultsRoute />
          </Box>

          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.secondary.dark,
            }}
          >
            <FiveMillionEmployees />
          </Box>

          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.primary.dark,
            }}
          >
            <Footer title="Mehr Möglichkeiten." url="/B2C/2#OrdersIn2022" />
          </Box>
        </ReactFullpage.Wrapper>
      );
    }}
  />
);

export default B2CTopic1;
