import React from 'react';
import { Box } from '@mui/material';
import theme from '../../../assets/theme';
import Footer from '../../../components/footer';
import ReactFullpage from '@fullpage/react-fullpage';
import {
  MoreOpportunities,
  PotentialJobRadlers,
  MillionBikes,
  EmployersIncrease,
  EntitledPersons,
} from '../../../sections';

const B2BTopic5 = () => (
  <ReactFullpage
    licenseKey={'YOUR_KEY_HERE'}
    credits={{ enabled: false }}
    scrollingSpeed={1000}
    anchors={[
      'PotentialJobRadlers',
      'MillionBikes',
      'EmployersIncrease',
      'EntitledPersons',
      'MoreOpportunities',
      'footer',
    ]}
    render={({ state, fullpageApi }) => {
      return (
        <ReactFullpage.Wrapper>
          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.primary.main,
            }}
          >
            <PotentialJobRadlers />
          </Box>

          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.secondary.main,
            }}
          >
            <MillionBikes />
          </Box>

          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.secondary.dark,
            }}
          >
            <EmployersIncrease />
          </Box>

          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.secondary.dark,
            }}
          >
            <EntitledPersons />
          </Box>

          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.primary.main,
            }}
          >
            <MoreOpportunities />
          </Box>

          <Box className="section">
            <Footer title="Mehr Dienstrad" url="/B2B/1#JobBikesSince2012" />
          </Box>
        </ReactFullpage.Wrapper>
      );
    }}
  />
);

export default B2BTopic5;
