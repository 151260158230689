import React from 'react';
import { Box } from '@mui/material';
import Typography from '../../components/typography';
import theme from '../../assets/theme';
import Lottie from 'react-lottie-player';
import useActiveSession from '../../hooks/useActiveSection';
import Doughnut92StrongBrandLottie from '../../assets/lottie/Doughnut_92%_starke_marke.json';
import { devices } from '../../assets/devices';

export const StrongBrand = () => {
  const [isActiveSession] = useActiveSession('#StrongBrand');

  return (
    <Box sx={{ backgroundColor: theme.palette.secondary.dark }}>
      <Box
        marginRight={8}
        height="100vh"
        display="flex"
        alignItems="center"
        justifyContent="space-around"
        sx={{ [devices.iPad]: { marginRight: 18 } }}
      >
        <Lottie
          loop
          play={isActiveSession}
          animationData={Doughnut92StrongBrandLottie}
          className="lottieIPadSmaller lottie-default"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        />

        <Box
          minWidth="fit-content"
          display="flex"
          flexDirection="column"
          gap={3}
        >
          <Typography variant="h4" fontWeight={700}>
            92&thinsp;%
          </Typography>
          <Typography fontWeight={300} variant="h6">
            Starke Marke, starkes Marketing:
            <br />
            Wir erreichen 92 % aller Erwerbstätigen.
          </Typography>

          <Typography fontWeight={300} variant="h6"></Typography>

          <Typography variant="body1">Altersgruppe 18 - 59 Jahre</Typography>
        </Box>
      </Box>
    </Box>
  );
};
