import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import Lottie from 'react-lottie-player';
import Confetti from '../../assets/lottie/Confetti.json';
import theme from '../../assets/theme';
import Typography from '../../components/typography';
import useActiveSession from '../../hooks/useActiveSection';

export const OrdersVolumeIn2023 = () => {
  const [isActiveSession] = useActiveSession('#OrdersVolumeIn2023');
  const [showText, setShowText] = useState(false);

  useEffect(() => {
    if (isActiveSession && !showText) {
      setShowText(true);
    }
  }, [isActiveSession]);

  return (
    <Box
      sx={{ backgroundColor: theme.palette.primary.main }}
      position="relative"
    >
      <Box zIndex={10} position={'relative'} paddingLeft={10} paddingTop={10}>
        <Typography variant="h2" color="primary.dark" fontWeight={700}>
          Über{' '}
          {isActiveSession ? (
            <CountUp start={0} end={1000000000} duration={2} separator="." />
          ) : (
            '1.000.000.000'
          )}{' '}
          €
        </Typography>

        <Typography color="primary.dark" variant="h3" fontWeight={300}>
          Bestellvolumen 2023
        </Typography>

        <Typography color="primary.dark" variant="h3" fontWeight={300}>
          Einfach wow!
        </Typography>
      </Box>

      <Lottie
        loop
        play={isActiveSession}
        animationData={Confetti}
        className="lottie-default"
        style={{
          position: 'absolute',
          top: '0',
          right: '0',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      />
    </Box>
  );
};
