import React from 'react';
import { Box } from '@mui/material';
import Typography from '../../components/typography';
import theme from '../../assets/theme';
import Lottie from 'react-lottie-player';
import useActiveSession from '../../hooks/useActiveSection';
import InstantClarificationLottie from '../../assets/lottie/Doughnut_sofortige_klaerung.json';
import InstantClarificationLottieIPad from '../../assets/lottie/Doughnut_sofortige_klaerung_iPad.json';
import { sizes } from '../../assets/devices';

export const InstantClarification = () => {
  const [isActiveSession] = useActiveSession('#InstantClarification');

  return (
    <Box sx={{ backgroundColor: theme.palette.primary.main }}>
      <Box
        marginRight={8}
        height="100vh"
        display="flex"
        alignItems="center"
        justifyContent="start"
        gap={4}
      >
        <Lottie
          loop
          play={isActiveSession}
          animationData={
            window.innerWidth > sizes.iPad
              ? InstantClarificationLottie
              : InstantClarificationLottieIPad
          }
          className="lottie-default"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        />

        <Box
          minWidth="fit-content"
          display="flex"
          flexDirection="column"
          gap={3}
        >
          <Typography color="primary.dark" variant="h4" fontWeight={700}>
            90&thinsp;%
            <br />
            sofortige Klärung
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
