import React, { useContext } from 'react';
import { Box, SxProps } from '@mui/material';
import Button from '../button';
import Reload from '../../assets/images/reload.svg';
import { Theme } from '@emotion/react';
import IdleContext from '../../context/idleContext';

type Props = {
  sx?: SxProps<Theme>;
};

const IdleModeButton = ({ sx }: Props) => {
  const idleContext = useContext(IdleContext);
  const { setIdle } = idleContext;

  return (
    <Button
      color="secondary"
      sx={{
        ...sx,
        width: '64px',
        height: '64px',
      }}
      onClick={() => setIdle(true)}
    >
      <Box display="flex" flexDirection="column">
        <img src={Reload} alt="Go to Idle Modle" />
      </Box>
    </Button>
  );
};

export default IdleModeButton;
