import React, { useState } from 'react';
import RoutesList from './routes';
import Template from './template';
import './App.css';
import IdleContext from './context/idleContext';

const App = () => {
  const [isIdle, setIdle] = useState<boolean>(true);

  return (
    <IdleContext.Provider value={{ isIdle, setIdle }}>
      <Template>
        <RoutesList />
      </Template>
    </IdleContext.Provider>
  );
};

export default App;
