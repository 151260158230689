import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import theme from '../../assets/theme';
import FillingText from '../../components/fillingText';
import useActiveSession from '../../hooks/useActiveSection';
import Typography from '../../components/typography';
import { devices, sizes } from '../../assets/devices';

export const PartnerInvest = () => {
  const [isActiveSession] = useActiveSession('#PartnerInvest');
  const [showText, setShowText] = useState(false);

  useEffect(() => {
    if (isActiveSession && !showText) {
      setShowText(true);
    }
  }, [isActiveSession]);

  return (
    <Box sx={{ backgroundColor: theme.palette.secondary.main }}>
      <Box paddingLeft={10} paddingTop={10}>
        <Box
          maxWidth="1350px"
          sx={{
            [devices.iPad]: { maxWidth: '1050px' },
            [devices.largeDesktop]: { maxWidth: '1520px' },
          }}
        >
          {showText && (
            <Typography
              color="primary.dark"
              variant="h3"
              sx={{ lineHeight: '115%', [devices.iPad]: { fontSize: '85px' } }}
            >
              {window.innerWidth > sizes.iPad ? (
                <FillingText>
                  Wir investieren in unsere Partnerschaft:
                  &amp;&amp;&amp;&amp;&amp;&amp;&amp;&amp; Mit
                  Millionen-Zuschüssen wie ServicePro und ProLastenrad.
                </FillingText>
              ) : (
                <FillingText>
                  Wir investieren in unsere Partnerschaft: Mit
                  Millionen-Zuschüssen wie ServicePro und ProLastenrad.
                </FillingText>
              )}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};
