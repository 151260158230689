import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import theme from '../../assets/theme';
import FillingText from '../../components/fillingText';
import useActiveSession from '../../hooks/useActiveSection';
import Typography from '../../components/typography';
import { devices } from '../../assets/devices';

export const ThisIsHow = () => {
  const [isActiveSession] = useActiveSession('#ThisIsHow');
  const [showText, setShowText] = useState(false);

  useEffect(() => {
    if (isActiveSession && !showText) {
      setShowText(true);
    }
  }, [isActiveSession]);

  return (
    <Box sx={{ backgroundColor: theme.palette.secondary.main }}>
      <Box paddingLeft={10} paddingTop={10}>
        <Box maxWidth="1440px">
          {showText && (
            <Typography
              color="primary.dark"
              variant="h3"
              sx={{ lineHeight: '115%', [devices.iPad]: { fontSize: '85px' } }}
            >
              <FillingText>
                So kommen JobRadler:innen noch schneller und bequemer an ihr
                Traumbike.
              </FillingText>
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};
