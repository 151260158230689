import React from 'react';
import { Box } from '@mui/material';
import theme from '../../assets/theme';
import Typography from '../../components/typography';
import useActiveSession from '../../hooks/useActiveSection';
import Lottie from 'react-lottie-player';
import Chart from '../../assets/lottie/Doughnut_Battery_80%.json';

export const EBikes = () => {
  const [isActiveSession] = useActiveSession('#EBikes');

  return (
    <Box sx={{ backgroundColor: theme.palette.primary.dark }}>
      <Box
        display="flex"
        alignItems="center"
        height="100vh"
        justifyContent="space-evenly"
        overflow="hidden"
      >
        <Box width="100%" maxWidth="684px">
          <Lottie
            loop
            play={isActiveSession}
            animationData={Chart}
            className="lottieIPadSmaller"
          />
        </Box>

        <Box>
          <Typography
            variant="h4"
            color="primary"
            fontWeight={700}
            sx={{ marginBottom: '24px' }}
          >
            80 %
          </Typography>

          <Typography color="primary" variant="h6">
            der JobRäder sind <b>E-Bikes</b>.
          </Typography>

          <Typography color="secondary.light" variant="h6">
            JobRad macht hochpreisige Räder
          </Typography>

          <Typography color="secondary.light" variant="h6">
            erschwinglich.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
