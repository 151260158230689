import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import theme from '../../assets/theme';
import FillingText from '../../components/fillingText';
import useActiveSession from '../../hooks/useActiveSection';
import Typography from '../../components/typography';
import { devices, sizes } from '../../assets/devices';

export const ProtectsEnvironment = () => {
  const [isActiveSession] = useActiveSession('#ProtectsEnvironment');
  const [showText, setShowText] = useState(false);

  useEffect(() => {
    if (isActiveSession && !showText) {
      setShowText(true);
    }
  }, [isActiveSession]);

  return (
    <Box sx={{ backgroundColor: theme.palette.secondary.main }}>
      <Box paddingLeft={10} paddingTop={10}>
        <Box maxWidth="1357px">
          {showText && (
            <Typography
              color="primary.dark"
              variant="h3"
              sx={{
                lineHeight: '115%',
                letterSpacing: '1px',
                [devices.iPad]: { fontSize: '85px' },
              }}
            >
              {window.innerWidth > sizes.iPad ? (
                <FillingText>
                  Das hat Zukunft! Radeln spart jede Menge Abgase &amp; ein und
                  schont so die Umwelt.
                </FillingText>
              ) : (
                <FillingText>
                  Das hat Zukunft! Radeln spart jede Menge Abgase ein und schont
                  so die Umwelt.
                </FillingText>
              )}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};
