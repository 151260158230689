import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

function useActiveSession(section: string) {
  const [isActive, setIsActive] = useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    setIsActive(location.hash === section);

    if (location.pathname && !location.hash) {
      checkSection();
    }
  }, [location]);

  const checkSection = () => {
    const topic = location.pathname.substring(location.pathname.length - 1);

    switch (topic) {
      case '1':
        if (section === '#JobBikesSince2012') {
          setIsActive(true);
          return;
        }
        break;
      case '2':
        if (section === '#OrdersIn2022') {
          setIsActive(true);
          return;
        }
        break;

      case '5':
        if (section === '#PotentialJobRadlers') {
          setIsActive(true);
          return;
        }
        break;
    }
  };

  return [isActive] as const;
}

export default useActiveSession;
