import React from 'react';
import { Box } from '@mui/material';
import theme from '../../../assets/theme';
import ReactFullpage from '@fullpage/react-fullpage';
import Footer from '../../../components/footer';
import {
  Leasing,
  BestPartnerYears,
  PartnerInvest,
  SupportCalls,
  InstantClarification,
} from '../../../sections';

const B2BTopic3 = () => (
  <ReactFullpage
    licenseKey={'YOUR_KEY_HERE'}
    credits={{ enabled: false }}
    scrollingSpeed={1000}
    anchors={[
      'Leasing',
      'PartnerInvest',
      'BestPartnerYears',
      'SupportCalls',
      'InstantClarification',
      'footer',
    ]}
    render={({ state, fullpageApi }) => {
      return (
        <ReactFullpage.Wrapper>
          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.secondary.main,
            }}
          >
            <Leasing />
          </Box>

          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.secondary.main,
            }}
          >
            <PartnerInvest />
          </Box>

          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.primary.dark,
            }}
          >
            <BestPartnerYears />
          </Box>

          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.primary.main,
            }}
          >
            <SupportCalls />
          </Box>

          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.primary.main,
            }}
          >
            <InstantClarification />
          </Box>

          <Box className="section">
            <Footer title="Maximal präsent." url="/B2B/4#LogoViews" />
          </Box>
        </ReactFullpage.Wrapper>
      );
    }}
  />
);

export default B2BTopic3;
