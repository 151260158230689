import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie-player';
import { devices } from '../../assets/devices';
import theme from '../../assets/theme';
import Typography from '../../components/typography';
import useActiveSession from '../../hooks/useActiveSection';

export const EmployersIncrease = () => {
  const [isActiveSession] = useActiveSession('#EmployersIncrease');
  const [animationData, setAnimationData] = useState<object>();
  const [firstRender, setFirstRender] = useState<boolean>(true);

  useEffect(() => {
    if (isActiveSession && firstRender) {
      import('../../assets/lottie/Map_Dots-B2C.json').then((value) => {
        setAnimationData(value as unknown as object);
      });
      setFirstRender(false);
    }
  }, [isActiveSession]);

  return (
    <Box sx={{ backgroundColor: theme.palette.secondary.dark }}>
      <Box
        marginLeft={20}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        height="100vh"
      >
        <Box
          sx={{
            [devices.iPad]: {
              minWidth: 'fit-content',
              width: '420px',
            },
          }}
        >
          <Typography
            fontWeight={700}
            color="secondary.light"
            variant="h4"
            sx={{ marginBottom: '24px' }}
          >
            70.000
          </Typography>

          <Typography color="secondary.light" variant="h6">
            Arbeitgeber:innen vertrauen in die <br /> Qualitätsführerin.
          </Typography>
        </Box>

        <Box className="lottie-wrapper">
          {animationData ? (
            <Lottie
              loop
              play={isActiveSession}
              animationData={animationData}
              className="lottie-default"
            />
          ) : null}
        </Box>

        <Box></Box>
      </Box>
    </Box>
  );
};
