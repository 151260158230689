import React, { useState } from 'react';
import { Box } from '@mui/material';
import theme from '../../assets/theme';
import Typography from '../../components/typography';
import useActiveSession from '../../hooks/useActiveSection';
import Lottie from 'react-lottie-player';
import Chart1 from '../../assets/lottie/Doughnut_Battery_2Strokes-1.0.json';
import Chart2 from '../../assets/lottie/Doughnut_Battery_2Strokes-2.0.json';

export const BikesInfo = () => {
  const [isActiveSession] = useActiveSession('#BikesInfo');
  const [secondStep, setSecondStep] = useState<boolean>(false);

  return (
    <Box
      paddingRight={2}
      sx={{ backgroundColor: theme.palette.primary.dark, overflow: 'hidden' }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-evenly"
        height="100vh"
      >
        {!secondStep ? (
          <>
            <Box className="lottie-wrapper">
              <Lottie
                loop
                play={!secondStep && isActiveSession}
                animationData={Chart1}
                onLoopComplete={() => setSecondStep((value) => !value)}
                className="lottie-default"
              />
            </Box>

            <Box width="672px">
              <Typography
                variant="h4"
                color="primary"
                fontWeight={700}
                sx={{ marginBottom: '24px' }}
              >
                80 %
              </Typography>

              <Typography color="primary" variant="h6">
                der JobRäder sind <b>Pedelecs</b> mit einem
              </Typography>

              <Typography color="primary" variant="h6">
                Durchschnittspreis von 4000 €.
              </Typography>
            </Box>
          </>
        ) : (
          <>
            {/* Dont remove Typography, because Lotties has a bug where you need to write the secondStep to play when you want to display differents lotties  */}
            <Typography sx={{ display: 'none' }}>
              {secondStep.toString()}
            </Typography>

            <Box className="lottie-wrapper">
              <Lottie
                loop
                play={secondStep && isActiveSession}
                animationData={Chart2}
                onLoopComplete={() => setSecondStep((value) => !value)}
                className="lottie-default"
              />
            </Box>

            <Box width="672px">
              <Typography
                variant="h4"
                color="secondary"
                fontWeight={700}
                sx={{ marginBottom: '24px' }}
              >
                50 %
              </Typography>

              <Typography color="secondary" fontWeight={700} variant="h6">
                E-Trekkingräder und E-Mountainbikes
              </Typography>

              <Typography color="secondary" variant="h6">
                machen die Hälfte der JobRäder aus.
              </Typography>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};
