import ReactFullpage from '@fullpage/react-fullpage';
import { Box } from '@mui/material';
import React from 'react';
import theme from '../../../assets/theme';
import Footer from '../../../components/footer';
import {
  LowerCosts,
  ParkingLot,
  ProtectsEnvironment,
  SavesCO2,
} from '../../../sections';

const B2CTopic5 = () => (
  <ReactFullpage
    licenseKey={'YOUR_KEY_HERE'}
    credits={{ enabled: false }}
    scrollingSpeed={1000}
    anchors={[
      'SavesCO2',
      'ProtectsEnvironment',
      'ParkingLot',
      'LowerCosts',
      'footer',
    ]}
    render={({ state, fullpageApi }) => {
      return (
        <ReactFullpage.Wrapper>
          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.primary.main,
            }}
          >
            <SavesCO2 />
          </Box>

          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.secondary.main,
            }}
          >
            <ProtectsEnvironment />
          </Box>

          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.primary.dark,
            }}
          >
            <ParkingLot />
          </Box>

          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.secondary.dark,
            }}
          >
            <LowerCosts />
          </Box>

          <Box className="section">
            <Footer title="Mehr Dienstrad." url="/B2C/1#JobBikesSince2012" />
          </Box>
        </ReactFullpage.Wrapper>
      );
    }}
  />
);

export default B2CTopic5;
