import { Box } from '@mui/material';
import React from 'react';
import Lottie from 'react-lottie-player';
import { sizes } from '../../assets/devices';
import BestPartnerYearsLottie from '../../assets/lottie/Best_Partner_Years.json';
import theme from '../../assets/theme';
import Typography from '../../components/typography';
import useActiveSession from '../../hooks/useActiveSection';

export const BestPartnerYears = () => {
  const [isActiveSession] = useActiveSession('#BestPartnerYears');

  return (
    <Box
      height="100vh"
      paddingLeft="80px"
      position="relative"
      sx={{
        backgroundColor: theme.palette.primary.dark,
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        height="100%"
      >
        <Box
          maxWidth={window.innerWidth <= sizes.iPad ? '469px' : '680px'}
          zIndex={10}
        >
          <Typography color="secondary.light" variant="h6" fontWeight={300}>
            Ihr habt uns wieder zur Qualitätspartnerin Nummer Eins gewählt! Und
            wir legen uns weiter ins Zeug für euch.
          </Typography>
        </Box>

        <Lottie
          loop
          play={isActiveSession}
          animationData={BestPartnerYearsLottie}
          className="fixedSize"
          style={{
            position: 'absolute',
            top: '0',
            right: '0',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        />
      </Box>
    </Box>
  );
};
