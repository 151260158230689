import { Button, styled } from '@mui/material';
import theme from '../../assets/theme';
import { ButtonProps } from './props';

const ButtonStyled = styled(Button)((props: ButtonProps) => ({
  textTransform: 'none',
  fontSize: 20,
  boxShadow: 'none',
  width: 94,
  height: 94,
  borderRadius: '100%',
  fontWeight: 700,
  filter: 'drop-shadow(0px 16px 16px rgba(0, 0, 0, 0.12))',
  color:
    props.color === 'primary'
      ? theme.palette.primary.dark
      : theme.palette.secondary.light,
  '&:hover': {
    boxShadow: 'none',
    backgroundColor:
      props.color === 'primary'
        ? theme.palette.primary.main
        : theme.palette.secondary.main,
    color:
      props.color === 'primary'
        ? theme.palette.primary.dark
        : theme.palette.secondary.light,
    borderColor:
      props.color === 'primary'
        ? theme.palette.primary.main
        : theme.palette.secondary.main,
  },
}));

export default ButtonStyled;
