import { Box } from '@mui/material';
import React from 'react';
import Lottie from 'react-lottie-player';
import { useLocation } from 'react-router-dom';
import Logo from '../../assets/images/whiteLogo.svg';
import B2BIdleFullHD from '../../assets/lottie/B2B_Idle_Full_HD.json';
import B2BIdleIPad from '../../assets/lottie/B2B_Idle_iPad_10.json';
import B2CIdleFullHD from '../../assets/lottie/B2C_Idle_Full_HD.json';
import B2CIdleIPad from '../../assets/lottie/B2C_Idle_iPad_10.json';
import theme from '../../assets/theme';
import PulsingButton from '../../components/pulsingButton';
import Typography from '../../components/typography';

type Props = {
  action: Function;
};

const IdleMode = (props: Props) => {
  const location = useLocation();
  const pathname = location.pathname.toUpperCase();
  const isB2B = pathname.includes('B2B');
  return (
    <Box
      height="100vh"
      overflow="hidden"
      sx={{ backgroundColor: theme.palette.primary.dark }}
    >
      <Box position="absolute" top="48px" left="48px" zIndex={99}>
        <img src={Logo} alt="Jobrad Logo" />
      </Box>

      <Box>
        <Lottie
          loop
          play
          animationData={
            window.innerWidth < 1920
              ? isB2B
                ? B2BIdleIPad
                : B2CIdleIPad
              : isB2B
              ? B2BIdleFullHD
              : B2CIdleFullHD
          }
          className="idleMode"
        />
      </Box>

      <Box position="absolute" width="100%" bottom="48px" zIndex={99}>
        <Box display="flex" justifyContent="center">
          <PulsingButton onClick={() => props.action()}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography variant="body1" color="primary.dark" fontWeight={700}>
                Los geht's!
              </Typography>
            </Box>
          </PulsingButton>
        </Box>
      </Box>
    </Box>
  );
};

export default IdleMode;
