import { createTheme } from '@mui/material';
import { devices } from './devices';

const theme = createTheme({
  palette: {
    text: {
      primary: '#B0E572',
      secondary: '#FFBCDF',
    },
    primary: {
      dark: '#2B373D',
      main: '#B0E572',
      light: '#3E7400',
    },
    secondary: {
      light: '#FFFFFF',
      main: '#FFBCDF',
      dark: '#12170B',
    },
  },
  typography: {
    fontFamily: ['Calmetta'].join(','),
    h1: {
      fontSize: 312,
      [devices.iPad]: {
        fontSize: 220,
      },
    },
    h2: {
      fontSize: 136,
      [devices.iPad]: {
        fontSize: 90,
      },
    },
    h3: {
      fontSize: 120,
      [devices.iPad]: {
        fontSize: 74,
      },
    },
    h4: {
      fontSize: 100,
      [devices.iPad]: {
        fontSize: 56,
      },
    },
    h5: {
      fontSize: 64,
      [devices.iPad]: {
        fontSize: 35,
      },
    },
    h6: {
      fontSize: 40,
      [devices.iPad]: {
        fontSize: 25,
      },
    },
    body1: {
      fontSize: 24,
      [devices.iPad]: {
        fontSize: 16,
      },
    },
    body2: {
      fontSize: 14,
      [devices.iPad]: {
        fontSize: 10,
      },
    },
  },
  components: {
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: '#80878B',
          maxWidth: '1440px',
          '& .MuiLinearProgress-bar': {
            borderRadius: '100px',
          },
        },
      },
    },
  },
  // TO DO: FONT CONFIG HERE INSTEAD IN APP.CSS
});

export default theme;
