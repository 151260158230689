import React from 'react';
import { Box } from '@mui/material';
import Typography from '../../components/typography';
import theme from '../../assets/theme';
import useActiveSession from '../../hooks/useActiveSection';
import CountUp from 'react-countup';

export const EmployeesLeasing = () => {
  const [isActiveSession] = useActiveSession('#EmployeesLeasing');

  return (
    <Box sx={{ backgroundColor: theme.palette.primary.main }}>
      <Box paddingLeft={6} marginTop={18}>
        <Typography
          color="primary.dark"
          variant="h1"
          sx={{
            marginBottom: '48px',
            display: 'inline-flex',
            alignItems: 'baseline',
          }}
        >
          {isActiveSession ? (
            <CountUp start={0} end={6000000} duration={2} separator="." />
          ) : (
            '6.000.000'
          )}
        </Typography>

        <Typography color="primary.dark" variant="h5">
          Mehr als 6.000.000
        </Typography>

        <Typography color="primary.dark" variant="h5">
          Beschäftigte können ein JobRad leasen.
        </Typography>

        <Typography color="primary.dark" variant="h5">
          Plus Mehrfachleasing.
        </Typography>
      </Box>
    </Box>
  );
};
