import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import theme from '../../assets/theme';
import FillingText from '../../components/fillingText';
import useActiveSession from '../../hooks/useActiveSection';
import Typography from '../../components/typography';
import { devices, sizes } from '../../assets/devices';

export const DiversityMeetsCompany = () => {
  const [isActiveSession] = useActiveSession('#DiversityMeetsCompany');
  const [showText, setShowText] = useState(false);

  useEffect(() => {
    if (isActiveSession && !showText) {
      setShowText(true);
    }
  }, [isActiveSession]);

  return (
    <Box sx={{ backgroundColor: theme.palette.secondary.main }}>
      <Box paddingLeft={10} paddingTop={10}>
        <Box maxWidth="1500px" sx={{ [devices.iPad]: { maxWidth: '1100px' } }}>
          {showText && (
            <Typography
              color="primary.dark"
              variant="h3"
              sx={{ lineHeight: '115%', [devices.iPad]: { fontSize: '85px' } }}
            >
              {window.innerWidth > sizes.iPad ? (
                <FillingText>
                  Mit uns hast du mehr vom Bike. Mehr Kundenkontakte und
                  Services liefert keiner.
                </FillingText>
              ) : (
                <FillingText>
                  Mit uns hast du mehr vom Bike. Mehr Kundenkontakte und
                  Services liefert keiner.
                </FillingText>
              )}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};
