import React from 'react';
import { Box } from '@mui/material';
import Logo from '../../assets/images/darkLogo.svg';
import CountUp from 'react-countup';
import Typography from '../../components/typography';
import theme from '../../assets/theme';
import useActiveSession from '../../hooks/useActiveSection';
import Lottie from 'react-lottie-player';
import DragIndicator from '../../assets/lottie/darkDragIndicator.json';

export const PotentialJobRadlers = () => {
  const [isActiveSession] = useActiveSession('#PotentialJobRadlers');

  return (
    <Box sx={{ backgroundColor: theme.palette.primary.main }}>
      <Box paddingLeft={6}>
        <Box marginTop={6} marginBottom={9}>
          <img src={Logo} alt="Jobrad Logo" />
        </Box>

        <Box position="absolute" height="100%" top="0" right="0">
          <Box display="flex" alignItems="center" height="100%">
            <Lottie loop play animationData={DragIndicator} />
          </Box>
        </Box>

        <Box position="relative" right={4.5} marginTop={9}>
          <Typography
            color="primary.dark"
            variant="h1"
            sx={{ marginBottom: '48px' }}
          >
            {isActiveSession ? (
              <CountUp start={0} end={5000000} duration={2} separator="." />
            ) : (
              '5.000.000'
            )}
          </Typography>
        </Box>

        <Typography variant="h5" color="primary.dark">
          Über 5 Mio. potenzielle JobRadler
        </Typography>

        <Typography variant="h5" color="primary.dark">
          stehen in den Startlöchern.
        </Typography>
      </Box>
    </Box>
  );
};
