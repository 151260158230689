import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import theme from '../../assets/theme';
import useActiveSession from '../../hooks/useActiveSection';
import Typography from '../../components/typography';
import Lottie from 'react-lottie-player';

export const FiveMillionEmployees = () => {
  const [isActiveSession] = useActiveSession('#FiveMillionEmployees');
  const [animationData, setAnimationData] = useState<object>();
  const [firstRender, setFirstRender] = useState<boolean>(true);

  useEffect(() => {
    if (isActiveSession && firstRender) {
      import('../../assets/lottie/Map_Dots-B2C.json').then((value) => {
        setAnimationData(value as unknown as object);
      });
      setFirstRender(false);
    }
  }, [isActiveSession]);

  return (
    <Box sx={{ backgroundColor: theme.palette.secondary.dark }}>
      <Box
        marginLeft={20}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        height="100vh"
      >
        <Box minWidth="390px">
          <Typography
            fontWeight={700}
            color="secondary.light"
            variant="h4"
            sx={{ marginBottom: '24px' }}
          >
            + 70.000
          </Typography>

          <Typography color="secondary.light" variant="h6">
            Arbeitgeber:innen bieten bereits
          </Typography>

          <Typography color="secondary.light" variant="h6">
            Dienstradleasing mit JobRad an.
          </Typography>

          <Typography
            color="secondary.light"
            variant="h6"
            sx={{ marginTop: '24px' }}
          >
            Das entspricht mehr als <b>6 Mio.</b>
          </Typography>

          <Typography color="secondary.light" variant="h6">
            Beschäftigten mit der Möglichkeit, ein
          </Typography>

          <Typography color="secondary.light" variant="h6">
            JobRad zu nutzen.
          </Typography>
        </Box>

        <Box className="lottie-wrapper">
          {animationData ? (
            <Lottie
              loop
              play={isActiveSession}
              animationData={animationData}
              className="lottie-default"
            />
          ) : null}
        </Box>

        <Box></Box>
      </Box>
    </Box>
  );
};
