import React from 'react';
import { Box } from '@mui/system';
import theme from '../../assets/theme';
import Typography from '../../components/typography';
import Lottie from 'react-lottie-player';
import Chart from '../../assets/lottie/linePriceComparison.json';
import useActiveSession from '../../hooks/useActiveSection';
import { devices } from '../../assets/devices';

export const PriceChartComparison = () => {
  const [isActiveSession] = useActiveSession('#PriceChartComparison');

  return (
    <Box sx={{ backgroundColor: theme.palette.secondary.dark }}>
      <Box mt={14} ml={24} sx={{ [devices.iPad]: { marginLeft: 14 } }}>
        <Box ml={15}>
          <Typography color="secondary.light" variant="h6">
            Das ist <b>doppelt so hoch</b> wie der
          </Typography>
          <Typography color="secondary.light" variant="h6">
            Durchschnittspreis für Fahrräder im
          </Typography>
          <Typography color="secondary.light" variant="h6">
            Neuradverkauf ohne JobRad.
          </Typography>
        </Box>

        <Box mt={6} className="lottie-wrapper">
          <Lottie
            loop
            play={isActiveSession}
            animationData={Chart}
            style={{ maxWidth: '1500px' }}
            className="lottie-default"
          />
        </Box>
      </Box>
    </Box>
  );
};
