import React from 'react';
import { Box } from '@mui/material';
import theme from '../../../assets/theme';
import Footer from '../../../components/footer';
import ReactFullpage from '@fullpage/react-fullpage';
import {
  DiversityMeetsCompany,
  ServicePackage,
  ServiceBudget,
} from '../../../sections';

const B2BTopic2 = () => (
  <ReactFullpage
    licenseKey={'YOUR_KEY_HERE'}
    credits={{ enabled: false }}
    scrollingSpeed={1000}
    anchors={[
      'ServicePackage',
      'DiversityMeetsCompany',
      'ServiceBudget',
      'footer',
    ]}
    render={({ state, fullpageApi }) => {
      return (
        <ReactFullpage.Wrapper>
          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.primary.main,
            }}
          >
            <ServicePackage />
          </Box>

          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.secondary.main,
            }}
          >
            <DiversityMeetsCompany />
          </Box>

          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.primary.main,
            }}
          >
            <ServiceBudget />
          </Box>

          <Box className="section">
            <Footer
              desktopTextWidth="1080px"
              title={['Maximaler Einsatz.', 'Maximal Qualität.']}
              url="/B2B/3#Leasing"
            />
          </Box>
        </ReactFullpage.Wrapper>
      );
    }}
  />
);

export default B2BTopic2;
