import React from 'react';
import { Box } from '@mui/material';
import Typography from '../typography';
import theme from '../../assets/theme';
import PulsingButton from '../pulsingButton';
import { useNavigate } from 'react-router-dom';
import { devices, sizes } from '../../assets/devices';
import { useRevelaer } from '../../helpers/useRevealer';
import { useModeNavs } from '../../helpers/useModeNavs';

const Footer = ({
  desktopTextWidth,
  title,
  url,
}: {
  desktopTextWidth?: string;
  title: string | string[];
  url: string;
}) => {
  const navigate = useNavigate();
  const reveal = useRevelaer();
  const { modeNavs } = useModeNavs();

  let currentUrlIndex = modeNavs.findIndex((nav) => nav.active);
  let targetUrlIndex = modeNavs.findIndex((nav) => nav.url === url);

  if (typeof title === 'string') {
    title = [title];
  }

  return (
    <Box
      height="100vh"
      paddingLeft="266px"
      paddingTop="389px"
      sx={{
        backgroundColor: theme.palette.primary.dark,
        [devices.iPad]: {
          paddingLeft: '96px',
          paddingTop: '280px',
        },
      }}
    >
      <Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box
            mt={2}
            maxWidth={
              window.innerWidth <= sizes.iPad
                ? '600px'
                : desktopTextWidth
                ? desktopTextWidth
                : '868px'
            }
          >
            <Typography
              color="secondary.light"
              variant="body1"
              sx={{ marginBottom: 2 }}
            >
              Nächstes Thema
            </Typography>

            {title.map((titleLine, index) => (
              <Typography
                key={'footer-title-' + index}
                color="secondary.light"
                variant="h3"
                fontWeight={400}
              >
                {titleLine}
              </Typography>
            ))}
          </Box>

          <Box>
            <PulsingButton
              onClick={() => {
                reveal(
                  currentUrlIndex > targetUrlIndex ? 'left' : 'right',
                  () => {
                    navigate(url);
                  }
                );
              }}
            >
              <Box display="flex" flexDirection="column" alignItems="center">
                <Typography
                  color="primary.dark"
                  fontWeight={700}
                  variant="body1"
                >
                  Jetzt
                </Typography>

                <Typography
                  color="primary.dark"
                  fontWeight={700}
                  variant="body1"
                >
                  weiterlesen
                </Typography>
              </Box>
            </PulsingButton>
          </Box>

          <Box></Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
