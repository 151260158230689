import React from 'react';
import { Box } from '@mui/material';
import Logo from '../../assets/images/darkLogo.svg';
import CountUp from 'react-countup';
import Typography from '../../components/typography';
import theme from '../../assets/theme';
import useActiveSession from '../../hooks/useActiveSection';
import Lottie from 'react-lottie-player';
import DragIndicator from '../../assets/lottie/darkDragIndicator.json';

export const ServicePackage = () => {
  const [isActiveSession] = useActiveSession('#ServicePackage');

  return (
    <Box sx={{ backgroundColor: theme.palette.primary.main }}>
      <Box paddingLeft={6}>
        <Box marginTop={6} marginBottom={9}>
          <img src={Logo} alt="Jobrad Logo" />
        </Box>

        <Box position="absolute" height="100%" top="0" right="0">
          <Box display="flex" alignItems="center" height="100%">
            <Lottie loop play animationData={DragIndicator} />
          </Box>
        </Box>

        <Box position="relative" right="24px" marginTop={9} marginBottom={6}>
          <Typography color="primary.dark" variant="h1">
            {isActiveSession ? (
              <CountUp start={0} end={80} duration={2} separator="." />
            ) : (
              80
            )}
            &#8201;%
          </Typography>
        </Box>

        <Typography variant="h5" color="primary.dark">
          der JobRäder werden mit Servicepaket gebucht.
        </Typography>
        <Typography variant="h5" color="primary.dark">
          Zusatzumsatz inklusive!
        </Typography>
      </Box>
    </Box>
  );
};
