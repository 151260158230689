import { Box } from '@mui/material';
import React from 'react';
import theme from '../../assets/theme';
import Typography from '../../components/typography';

export const Spritgeld = () => (
  <Box sx={{ backgroundColor: theme.palette.primary.main }}>
    <Box paddingLeft={10} paddingTop={16}>
      <Typography variant="h2" color="primary.dark" fontWeight={700}>
        + 540 €
      </Typography>

      <Typography color="primary.dark" variant="h3">
        Spritgeld lässt sich pro Jahr
      </Typography>

      <Typography color="primary.dark" variant="h3">
        sparen, wenn man mit dem
      </Typography>

      <Typography
        color="primary.dark"
        variant="h3"
        sx={{ marginBottom: '48px' }}
      >
        Fahrrad statt dem Auto fährt.
      </Typography>

      <Typography color="primary.dark" variant="h6">
        * Bei einer Strecke von 10 km pro Tag
      </Typography>
    </Box>
  </Box>
);
