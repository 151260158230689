import React from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import theme from '../assets/theme';

const Template = ({ children }: any) => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    {children}
  </ThemeProvider>
);

export default Template;
