import { Box } from '@mui/material';
import React from 'react';
import CountUp from 'react-countup';
import Lottie from 'react-lottie-player';
import { devices } from '../../assets/devices';
import Logo from '../../assets/images/darkLogo.svg';
import DragIndicator from '../../assets/lottie/darkDragIndicator.json';
import theme from '../../assets/theme';
import Typography from '../../components/typography';
import useActiveSession from '../../hooks/useActiveSection';

export const Leasing = () => {
  const [isActiveSession] = useActiveSession('#Leasing');

  return (
    <Box sx={{ backgroundColor: theme.palette.secondary.main }}>
      <Box paddingLeft={6}>
        <Box marginTop={6} marginBottom={9}>
          <img src={Logo} alt="Jobrad Logo" />
        </Box>

        <Box position="absolute" height="100%" top="0" right="0">
          <Box display="flex" alignItems="center" height="100%">
            <Lottie loop play animationData={DragIndicator} />
          </Box>
        </Box>

        <Typography
          color="primary.dark"
          variant="h1"
          sx={{
            marginBottom: '48px',
            display: 'inline-flex',
            alignItems: 'baseline',
            [devices.iPad]: { fontSize: '180px!important' },
          }}
        >
          {isActiveSession ? (
            <CountUp start={0} end={16000000} duration={2} separator="." />
          ) : (
            '16.000.000'
          )}
          <Typography color="primary.dark" variant="h4">
            €
          </Typography>
        </Typography>

        <Typography color="primary.dark" variant="h5">
          ServicePro-Werkstattzuschuss für unsere
        </Typography>

        <Typography color="primary.dark" variant="h5">
          Fachhandelspartner:innen.
        </Typography>

        <Typography color="primary.dark" variant="body1" sx={{ marginTop: 2 }}>
          * allein bis Sommer 2024
        </Typography>
      </Box>
    </Box>
  );
};
