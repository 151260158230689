import React from 'react';
import { Box } from '@mui/material';
import CountUp from 'react-countup';
import Typography from '../../components/typography';
import theme from '../../assets/theme';
import useActiveSession from '../../hooks/useActiveSection';

export const MoreOpportunities = () => {
  const [isActiveSession] = useActiveSession('#MoreOpportunities');

  return (
    <Box sx={{ backgroundColor: theme.palette.primary.main }}>
      <Box paddingLeft={10} paddingTop={16}>
        <Typography variant="h2" color="primary.dark" fontWeight={700}>
          +{' '}
          {isActiveSession ? (
            <CountUp start={0} end={500000} duration={2} separator="." />
          ) : (
            '500.000'
          )}
        </Typography>

        <Typography color="primary.dark" variant="h3">
          Chancen, in Zukunft noch mehr
        </Typography>

        <Typography color="primary.dark" variant="h3">
          Menschen gemeinsam aufs Rad
        </Typography>

        <Typography color="primary.dark" variant="h3">
          zu bringen.
        </Typography>
      </Box>
    </Box>
  );
};
