import React, { useContext } from 'react';
import { Box } from '@mui/material';
import IdleModeButton from '../idleModeButton';
import { useLocation, useNavigate } from 'react-router-dom';
import IdleContext from '../../context/idleContext';
import Menu from '../menu';
import { devices } from '../../assets/devices';

const Wrapper = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const idleContext = useContext(IdleContext);
  const { setIdle } = idleContext;

  const changeMode = () => {
    setIdle(true);
    navigate('/');
  };

  return (
    <Box position="sticky" zIndex={99}>
      <Box
        position="absolute"
        color="transparent"
        left={0}
        onClick={changeMode}
        zIndex={999}
      >
        Mode
      </Box>

      {location.hash !== '#footer' ? (
        <Box position="absolute" right="32px" top="29px">
          <IdleModeButton />
        </Box>
      ) : null}

      <Box position="absolute" width="100%">
        <Box
          position="relative"
          sx={{
            top: 'calc(96vh - 48px)',
            [devices.iPad]: {
              top: 'calc(96vh - 64px)',
            },
          }}
        >
          <Menu />
        </Box>
      </Box>
    </Box>
  );
};

export default Wrapper;
