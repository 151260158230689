import React from 'react';
import { Box } from '@mui/material';
import theme from '../../../assets/theme';
import Footer from '../../../components/footer';
import ReactFullpage from '@fullpage/react-fullpage';
import {
  CompanyBikeLeasing,
  EBikes,
  LeasingIsChepear,
  Spritgeld,
} from '../../../sections';

const B2CTopic3 = () => (
  <ReactFullpage
    licenseKey={'YOUR_KEY_HERE'}
    credits={{ enabled: false }}
    scrollingSpeed={1000}
    anchors={[
      'CompanyBikeLeasing',
      'LeasingIsChepear',
      'EBikes',
      'Spritgeld',
      'footer',
    ]}
    render={({ state, fullpageApi }) => {
      return (
        <ReactFullpage.Wrapper>
          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.secondary.main,
            }}
          >
            <CompanyBikeLeasing />
          </Box>

          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.secondary.main,
            }}
          >
            <LeasingIsChepear />
          </Box>

          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.primary.dark,
            }}
          >
            <EBikes />
          </Box>

          <Box
            className="section"
            sx={{
              backgroundColor: theme.palette.primary.main,
            }}
          >
            <Spritgeld />
          </Box>

          <Box className="section">
            <Footer title="Mehr Gesundheit." url="/B2C/4#SickDays" />
          </Box>
        </ReactFullpage.Wrapper>
      );
    }}
  />
);

export default B2CTopic3;
