import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import theme from '../../assets/theme';
import FillingText from '../../components/fillingText';
import useActiveSession from '../../hooks/useActiveSection';
import Typography from '../../components/typography';
import { devices, sizes } from '../../assets/devices';

export const Diversity = () => {
  const [isActiveSession] = useActiveSession('#Diversity');
  const [showText, setShowText] = useState(false);

  useEffect(() => {
    if (isActiveSession && !showText) {
      setShowText(true);
    }
  }, [isActiveSession]);

  return (
    <Box sx={{ backgroundColor: theme.palette.secondary.main }}>
      <Box paddingLeft={10} paddingTop={10}>
        <Box maxWidth="1380px">
          {showText && (
            <Typography
              color="primary.dark"
              variant="h3"
              sx={{ lineHeight: '115%', [devices.iPad]: { fontSize: '85px' } }}
            >
              {window.innerWidth > sizes.iPad ? (
                <FillingText>
                  Gemeinsam mit Herstellern und dem Fachhandel &amp; &amp;
                  bringen wir Vielfalt in das Dienstradleasing. Denn &amp; &amp;
                  jedes Rad kann ein JobRad sein.
                </FillingText>
              ) : (
                <FillingText>
                  Gemeinsam mit Herstellern und dem Fachhandel bringen wir
                  Vielfalt in das Dienstradleasing. Denn jedes Rad kann ein
                  JobRad sein.
                </FillingText>
              )}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};
