import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import Lottie from 'react-lottie-player';
import ServicesBubble from '../../assets/lottie/Neue_Kundengruppen_Bubble_1920x1080.json';
import ServicesBubbleIPad from '../../assets/lottie/Neue_Kundengruppen_Bubble_iPad-10.json';

import useActiveSession from '../../hooks/useActiveSection';
import theme from '../../assets/theme';

export const ReturningCustomers = () => {
  const [isActiveSession] = useActiveSession('#ReturningCustomers');

  return (
    <Box
      className="bubbles"
      sx={{
        backgroundColor: theme.palette.primary.dark,
      }}
    >
      <Lottie
        loop
        play={isActiveSession}
        animationData={
          window.innerWidth < 1920 ? ServicesBubbleIPad : ServicesBubble
        }
        className="lottie-default"
        style={{
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      />
    </Box>
  );
};
