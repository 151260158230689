import React from 'react';
import { TypographyProps } from './props';
import { Typography as TypographyMUI } from '@mui/material';

const Typography = ({
  color,
  fontWeight,
  variant,
  children,
  sx,
}: TypographyProps) => (
  <TypographyMUI
    color={color || 'primary.main'}
    fontWeight={fontWeight || 300}
    variant={variant}
    sx={{ lineHeight: 1, ...sx }}
  >
    {children}
  </TypographyMUI>
);

export default Typography;
