import React from 'react';
import { ButtonProps } from './props';
import ButtonStyled from './styles';

const Button = ({ color, onClick, children, sx }: ButtonProps) => (
  <ButtonStyled
    type="button"
    variant="contained"
    color={color || 'primary'}
    onClick={onClick}
    sx={sx}
  >
    {children}
  </ButtonStyled>
);

export default Button;
